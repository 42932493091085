// Angular Imports
import { Injectable } from '@angular/core';
// 3rd party
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Store
import { Store } from '@ngrx/store';
// store actions
import * as displayStoreActions from '../../root-store/display-store/display.actions';
// Modal
import { ErrorModalComponent } from '../../shared/modals/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private modalService: NgbModal, private store: Store) {}

  onGeneralError(e: any) {
    this.store.dispatch(displayStoreActions.hideDisplayLoadingIndicator());
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.headerText = 'Error!';
    modalRef.componentInstance.bodyTextEntries.push(
      'An error occured.',
      'Please try again or contact technical support.'
    );
    console.log(e);
  }
}
